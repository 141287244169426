import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const $videos = $('.home-hero .content-background-image__video');
    $videos.each((index, el) => {
        el.removeAttribute('loop');
        el.addEventListener('ended', () => {
            $('.home-hero-quote').addClass('home-hero-quote--show');
        });
    });
});
